@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf');
}

.hideHorizontalScroll {
  overflow-x: hidden !important;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
}
